.blurred {
    filter: blur(4px);
  }


.DevScreenContainer{
  margin-top: 1%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}


/* stars_tbl_comp.css */

.add-cell {
  text-align: center;
  vertical-align: middle;
}

.add-icon {
  color: rgb(71, 233, 71);
  font-size: 14px;
  cursor: pointer; /* Add pointer cursor */
  transition: transform 0.2s; /* Add transition for hover effect */
}

.add-icon:hover {
  transform: scale(1.2); /* Increase icon size on hover */
}
