.list-group-projects .list-group-item:hover {
    cursor: pointer;
}


.list-group-projects{
    margin-top: 5%;
}

.h1_main_projects{
    color: #0e6ffc;
    margin-top: 3%;
    font-weight: bold;
}

.h4_project_title{
    font-weight: bold;
}


.ListItemProjects{
    margin-top: 2%;
    width: 100%;
    overflow-x: auto;
    border-radius: 20px!important;
    border: 0.8px solid #cccccc7d!important
}

.list-group-projects{
    margin-top: 0% !important;
}