.ResultScreenContainer{
    height: 100vh;
    margin-bottom: 2%;
    margin-top: 1.5%;
    margin-left: 1%;
    margin-right: 1%;

  }


.unlock{
  padding-right: 2%;
  cursor: pointer;
}

