/* Header.css */

/* ... your existing styles ... */


.custom-navbar .nav-item {
  padding-left: 1rem; /* Reset default padding */
}

/* Add more space between header elements */
.custom-navbar .nav-link {
  margin-right: 15px; /* Add space between individual links */
}

/* Adjust the spacing within dropdown menus */
.custom-navbar .nav-item .dropdown-menu {
  padding: 15px; /* Add padding around dropdown items */
}

.container_header {
  display: flex;
  justify-content: space-between; /* Distribute items along the main axis */
  align-items: center; /* Align items along the cross axis */
}

/* Add more styles as needed */
