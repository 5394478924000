.Form_login_main{
    margin-top: 4%;
    height: 100vh;

}

.submit_register{
    margin-top: 2%;
}

.pass_form{
    margin-top: 2%;
}


.Form.Group {
    padding-top: 10px;
  }