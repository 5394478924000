
.StarScreenContainer{
  margin-top: 1% !important;
  margin-left: 0.5% !important;
  margin-right: 0.5% !important;
}

/* saved_tbl_comp.css */
/* Add styles for the delete button and icon */
.delete-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the container takes up the entire cell height */
}

.delete-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.delete-icon {
  color: red;
}


.delete-icon:hover {
  transform: scale(1.4); /* Increase icon size on hover */
}
