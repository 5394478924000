
html {
  font-family: 'Lato',  sans-serif !important;
}

body {
    /* background-color: #f3f2efa8 !important; */
    background-color: white;
    /* #F5F2F3 */
}

footer{
  bottom: 0;
  width: 100%;
  margin-top: auto;
}

.py-3 {
  padding-top: 0% !important;
}



.form-control{
  border-radius: 5px;
  color: black;
}

.Latest{
  margin-top: 2%;
}


@media (min-width: 1400px)
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    /* max-width: 1320px; */
}