.gradient-custom-2 {
    
 /*   
    
    /* fallback for old browsers */
    background: #ffffff; 
    
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: -webkit-linear-gradient(to right, rgba(251, 194, 235, 1), rgba(166, 193, 238, 1)); */
    
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* background: linear-gradient(to right, rgba(251, 194, 235, 1), rgba(166, 193, 238, 1)) */
    }


.btn{
    text-transform: none; /* to avoid auto upper case from MD react UI lib */
}