
.tech_creator{
    color: rgb(143, 128, 109);
}




.tech_creator:hover {
    cursor: pointer;
    color: rgb(144, 133, 117);
}


.text_slogan_learn_more{
    color: rgb(168, 157, 143);
    text-align: left;
    max-width: 70%;
    margin-top: 6%;
    font-size: 2.5em;
}

.text_slogan_learn_more:hover{
    cursor: pointer;
    color: rgb(144, 133, 117);
}

  
.text_slogan {
    max-width: 100%;
    font-size: 2em;
}


/* UserInput.css */
.user-input {
    border-bottom: 2px solid #ccc; /* Line under the input */
    padding: 10px 1; /* Increase the height of the input */
    font-size: 15px; /* Increase the font size */
    transition: border-bottom-color 0.3s ease; /* Smooth transition for the line color */
  }
  
  .tick-line {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 2px;
    height: 0;
    transform-origin: center bottom;
    transition: height 0.3s ease; /* Smooth transition for the ticking line */
  }

  .user-input:focus + .tick-line {
    height: 20px; /* Length of the ticking vertical line */
  }


.upper_page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0% !important;
    padding-top: 0% !important;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    padding: 0;
    /* background-image: linear-gradient(to bottom, transparent, rgb(254, 254, 254)), url('ben_white.jpg'); */
    background-image: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.5));
    background-size: cover;
    background-position: center;
}

.left-rectangle {
    flex: 1;
    max-width: 50%; 
    display: flex; /* Add flex display */
    flex-direction: column;
    justify-content: center; /* Vertically center the content */
    align-items: flex-start; /* Align the content to the start (left side) */
    padding-left: 10%;
  }
  

.right-rectangle {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  flex: 1;
  max-width: 50%;
  min-height: 200px; /* Set a minimum height to prevent collapse */
  }

/* Add a media query for mobile-like size */
@media (max-width: 768px) {
  .upper_page {
    flex-direction: column; /* Stack the flex items vertically */
  }

  .left-rectangle{
    min-width: 100%; /* Each rectangle should take the full width on mobile-like devices */
    align-items: center; /* Center the content horizontally on mobile-like devices */

  }
  .right-rectangle {
    min-width: 100%; /* Each rectangle should take the full width on mobile-like devices */
  }
}


.COBE_GLOBE {
    max-width: 100%; /* Limit the width of the COBE_GLOBE component */
  }


  