.SavedScreenContainer{
    height: 100vh;
    margin-bottom: 2%;
    margin-left: 2%;
    margin-right: 2% !important;
  }





  .center_no_project {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }