.DevScreenContainer{
    height: 100vh;
    margin-bottom: 2%;
    margin-top: 1%;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }


.unlock{
  padding-right: 2%;
  cursor: pointer;
}


.blurred {
  filter: blur(4px);
}


